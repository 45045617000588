import ServiceApi from '../ServiceApi';
import ServiceReconciliationFilter from './ServiceReconciliationFilter';
import ServiceReconciliationSummary from './ServiceReconciliationSummary';
import ServiceReconciliationTable from './ServiceReconciliationTable';
import ServiceReconciliationTransaction from './ServiceReconciliationTransaction';

export default class ServiceReconciliation {
    static _microserviceName = 'free';

    static _requestRouts = {
        getOfferTags: '/new_map/offer-tags',
        postOfferSend: '/new_map/offer-send',
        postOfferFinish: '/transactions/group-finish',
        postChangeState: '/new_map/change-state',
        getDownloadLink: '/new_map/download',
        getTransactionsSelectOptions: '/transactions/edit-select',
        postTransactionsEdit: '/transactions/edit-items'
    };

    static async getTransactionsSelectOptions(reqParams = {}, actionAfter = () => {}, actionError = () => {}) {
        await this._getTransactionsSelectOptions(reqParams, actionAfter, actionError)
    }

    static async _getTransactionsSelectOptions(params = {}, actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._getTransactionsSelectOptionsBefore(params)

        try {
            const res = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.getTransactionsSelectOptions,
                reqParams
            );
            const {data = {}} = res;
            const {data: resBody = {}} = data;

            const optionsData = this._getTransactionsSelectOptionsAfter(resBody)

            actionAfter(optionsData);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _getTransactionsSelectOptionsBefore(params = {}) {
        return {
            field: String(params?.field ?? ""),
            query: String(params?.query ?? ""),
            supplier: String(params?.supplier ?? ""),
            page: Number(params?.page ?? 1),
            'per-page': Number(params?.perPage ?? 20),
        }
    }

    static _getTransactionsSelectOptionsAfter(resBody = {}) {
        resBody.items = resBody.items.map(item => ({ ...item, subtitle: item.inn ? `(${ item.inn })` : '' }));
        return resBody
    }

    static async postTransactionsEdit(reqBody = {}, actionAfter = () => {}, actionError = () => {}) {
        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.postTransactionsEdit, reqBody);
            const {data = {}} = res;
            const {data: resBody = {}} = data;

            actionAfter(resBody);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static async getOfferTags(actionAfter = () => {}, actionError = () => {}) {
        await this._getOfferTags(actionAfter, actionError);
    }

    static async _getOfferTags(actionAfter = () => {}, actionError = () => {}) {
        try {
            const res = await ServiceApi.get(this._microserviceName, this._requestRouts.getOfferTags ?? '');
            const {data = {}} = res;
            const {data: resBody = {}} = data;
            const offerTags = this._getOfferTagsAfter(resBody);
            actionAfter(offerTags);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _getOfferTagsAfter(resBody = {}) {
        const {tags = {}} = ServiceReconciliationFilter._filterItemsTotalScheme;
        const {getValuesAfter = () => {}} = tags;

        return getValuesAfter(resBody);
    }

    static async postOfferSend(kitId = '', kitName = '', loginUser = '', offerTagsSelected = [], actionAfter = () => {}, actionError = () => {}) {
        await this._postOfferSend(kitId, kitName, loginUser, offerTagsSelected, actionAfter, actionError);
    }

    static async postOfferFinish(kitId = '', actionAfter = () => {}, actionError = () => {}) {
        await this._postOfferFinish(kitId, actionAfter, actionError);
    }

    static async _postOfferFinish(kitId = '', actionAfter = () => {}, actionError = () => {}) {
        try {
            await ServiceApi.post(this._microserviceName, this._requestRouts.postOfferFinish ?? '', {
                set_id: kitId
            });
            actionAfter();
        } catch (error) {
            console.log(error);

            const {response = {}} = error;
            const {data = {}} = response;
            const {data: errorData = {}} = data;
            const {set_id: errorEmail = []} = errorData;
            const errorEmailStr = String(errorEmail ?? '');

            actionError(errorEmailStr);
        }
    }

    static async _postOfferSend(kitId = '', kitName = '', loginUser = '', offerTagsSelected = [], actionAfter = () => {}, actionError = () => {}) {
        const reqBody = this._postOfferSendBefore(kitId, kitName, loginUser, offerTagsSelected);

        try {
            await ServiceApi.post(this._microserviceName, this._requestRouts.postOfferSend ?? '', reqBody);
            actionAfter();
        } catch (error) {
            console.log(error);

            const {response = {}} = error;
            const {data = {}} = response;
            const {data: errorData = {}} = data;
            const {receiver_email: errorEmail = []} = errorData;
            const errorEmailStr = String(errorEmail ?? '');

            actionError(errorEmailStr);
        }
    }

    static _postOfferSendBefore(kitId = '', kitName = '', loginUser = '', offerTagsSelected = []) {
        const reqBody = {
            set_name: String(kitName),
            set_id: String(kitId),
            receiver_email: String(loginUser),
            tags: offerTagsSelected.map(tag => String(tag))
        };

        return reqBody;
    }

    static async postChangeState(priceId = '', agree = false, actionAfter = () => {}, actionError = () => {}) {
        await this._postChangeState(priceId, agree, actionAfter, actionError);
    }

    static async _postChangeState(priceId = '', agree = false, actionAfter = () => {}, actionError = () => {}) {
        const reqBody = this._postChangeStateBefore(priceId, agree);

        try {
            await ServiceApi.post(this._microserviceName, this._requestRouts.postChangeState ?? '', reqBody);
            actionAfter();
        } catch (error) {
            console.log(error);
            const errorMsg = this._postChangeStateError(error);
            actionError(errorMsg);
        }
    }

    static _postChangeStateBefore(priceId = '', agree = false) {
        const reqBody = {
            action: agree ? 'approved' : 'done',
            transactions: Array.isArray(priceId) ? priceId : [String(priceId)]
        };

        return reqBody;
    }

    static _postChangeStateError(error = {}) {
        const { response = {} } = error;
        const { data: responseData = {} } = response;
        const { data: responseErrors = {} } = responseData;
        const {count_map_not_matched: countMapNotMathed = '0', count_not_valid: countNotValid = '0', count_supplier_not_matched: countSupplierNotMatched = '0'} = responseErrors;
        const errorMsg = `
            В выборке: 
            
            ${countMapNotMathed} размещений не сверенных с мапом; 
            ${countNotValid} размещений без назначенных юр. лиц;
            ${countSupplierNotMatched} размещений не сверенных с оператором.
            
            Исправьте и попробуйте снова.
        `;

        return errorMsg
    }

    static async getDownloadLink(actionType = '', filterData = [], actionAfter = () => {}, actionError = () => {}) {
        await this._getDownloadLink(actionType, filterData, actionAfter, actionError);
    }

    static async _getDownloadLink(actionType = '', filterData = [], actionAfter = () => {}, actionError = () => {}) {
        const reqBody = this._getDownloadLinkBefore(actionType, filterData);

        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.getDownloadLink ?? '', reqBody);
            const {data = {}} = res;
            const {data: resBody = {}} = data;

            const link = this._getDownloadLinkAfter(resBody);
            actionAfter(link);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _getDownloadLinkBefore(actionType = '', filterData = []) {
        const filterDataBefore = ServiceReconciliationFilter.getFilterBefore(filterData);
        
        const reqBody = {
            action: String(actionType),
            ...filterDataBefore?.filter
        };

        return reqBody;
    }

    static _getDownloadLinkAfter(resBody = {}) {
        const {url = ''} = resBody;
        return `${process.env.VUE_APP_FREE_BACKEND_HOST}/${url}`;
    }

    static async getFilter(pageType = '', filterData = [], kitId = '', actionAfter = () => {}, actionError = () => {}) {
        await ServiceReconciliationFilter.getFilter(pageType, filterData, kitId, actionAfter, actionError);
    }

    static async getSummary(pageType = '', filterData = [], kitId = '', actionAfter = () => {}, actionError = () => {}) {
        await ServiceReconciliationSummary.getSummary(pageType, filterData, kitId, actionAfter, actionError);
    }

    static async setTableColumnsSchemeData(pageType = '', tableColumns = [], actionAfter = () => {}, actionError = () => {}) {
        await ServiceReconciliationTable.setTableColumnsSchemeData(pageType, tableColumns, actionAfter, actionError);
    }

    static async getTable(pageType = '', filterData = [], kitId = '', page = 1, actionAfter = () => {}, actionError = () => {}) {
        await ServiceReconciliationTable.getTable(pageType, filterData, kitId, page, actionAfter, actionError);
    }

    static async toggleRevise(priceId = '') {
        return ServiceReconciliationTransaction.toggleRevise(priceId);
    }

    static async toggleRevises(priceIds = [], isRevised = false) {
        return ServiceReconciliationTransaction.toggleRevises(priceIds, isRevised);
    }

    static async transactionsRemove(priceIds = []) {
        return ServiceReconciliationTransaction.transactionsRemove(priceIds);
    }

    static async sendBudget(filterData = []) {
        return ServiceReconciliationTransaction.sendBudget(filterData);
    }
}
